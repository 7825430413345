import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { auth, addDrug } from "../../logic/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100%",
  },
  formControl: {
    minWidth: "100%",
  },
  saveButton: {
    float: "right",
  },
}));

type Props = {
  setLastUpdated: (x: number) => void
}

export default function AddDrug({ setLastUpdated }: Props) {
  const classes = useStyles();
  const user = useAuthState(auth)[0];
  
  // new drug data
  const [newDrugName, setNewDrugName] = useState<string>("");
  const [newDrugUnit, setNewDrugUnit] = useState<string>("");
  const [newDrugIncrement, setNewDrugIncrement] = useState<number>(1);
  const [newDrugHealthy, setNewDrugHealthy] = useState<boolean>(false);
  const [savingData, setSavingData] = useState<boolean>(false);

  const saveEntry = async () => {
    setSavingData(true);
    await addDrug(
      user.uid,
      newDrugName,
      newDrugUnit,
      newDrugIncrement,
      newDrugHealthy
    );
    setSavingData(false);
    setLastUpdated(Date.now());
    alert("Drug Saved");
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Add custom Drug
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              helperText="Name"
              placeholder="Drug Name"
              value={newDrugName}
              onChange={(e) => setNewDrugName(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              helperText="Unit"
              placeholder="Drug Unit (eg. Joints)"
              value={newDrugUnit}
              onChange={(e) => setNewDrugUnit(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              type="number"
              helperText="Increment"
              placeholder="Increment eg 25 milligrams"
              value={newDrugIncrement}
              onChange={(e) => setNewDrugIncrement(parseInt(e.target.value))}
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={newDrugHealthy}
                onChange={() => {
                  setNewDrugHealthy(!newDrugHealthy);
                }}
              />
            }
            className={classes.formControl}
            label="Is healthy?"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={newDrugName === "" || newDrugUnit === "" || savingData}
            className={classes.saveButton}
            onClick={() => saveEntry()}
          >
            Add Drug
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

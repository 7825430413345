import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { EnhancedRecord } from "../../types";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  formControl: {
    minWidth: "100%",
  },
  barColorPrimary: {
    backgroundColor: "green",
  },
  colorPrimary: {
    backgroundColor: "red",
  },
  root: {
    height: 16,
  },
  descContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

type Props = {
  entries: Array<EnhancedRecord>;
};

const ALL = "ALL";

interface BalanceType {
  healthy: number;
  unhealthy: number;
}

export default function Balance({ entries }: Props) {
  const classes = useStyles();
  const [year, setYear] = useState(ALL);
  const years = new Set<string>();
  years.add(ALL);

  const yearData: {
    [key: string]: BalanceType;
  } = {};
  yearData[ALL] = {
    healthy: 0,
    unhealthy: 0,
  };

  entries.forEach((e) => {
    const useDate = new Date(e.useDate);
    const curYear = `${useDate.getFullYear()}`;
    years.add(curYear);
    if (!(curYear in yearData)) {
      yearData[curYear] = {
        healthy: 0,
        unhealthy: 0,
      };
    }
    if (year === ALL || curYear === year) {
      const dataKey = e.healthy ? "healthy" : "unhealthy";
      yearData[curYear][dataKey] += 1;
      yearData[ALL][dataKey] += 1;
    }
  });

  const diff = yearData[year]["healthy"] + yearData[year]["unhealthy"];
  const progressValue =
    diff !== 0 ? Math.round((100 * yearData[year]["healthy"]) / diff) : 0;
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h5">
        Balance
      </Typography>
      <div className={classes.descContainer}>
        <Typography component="span" variant="subtitle1">
          This stat shows the distribution between healthy vs. unhealthy drugs
        </Typography>
      </div>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="year-select-label">Year</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select-label"
            value={year}
            onChange={(e) => setYear(e.target.value as string)}
          >
            {[...years].map((y) => (
              <MenuItem value={y} key={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid container xs={12} className={classes.descContainer}>
        <Grid item xs={6}>
          {yearData[year]["healthy"]} healthy Drugs
        </Grid>
        <Grid item xs={6}>
          {yearData[year]["unhealthy"]} unhealthy Drugs
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
            root: classes.root,
          }}
        />
      </Grid>
    </Grid>
  );
}

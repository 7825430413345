import Grid from "@material-ui/core/Grid";
import { EnhancedRecord } from "../../types";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { MONTHLY, WEEKLY } from "./BinningPicker";
import getColorArray from "./color";

type StackedAreaChart = {
  entries: Array<EnhancedRecord>;
  selectedFromYear: string;
  selectedToYear: string;
  selectedHabits: string[];
  currentBin: string;
};

function getWeekNumber(dateObj: Date) {
  var date = new Date(dateObj.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
}

function getBinKey(date: Date, bin: string) {
  if (bin === WEEKLY) {
    const weekNumber = getWeekNumber(date);
    const newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    return `${newDate.getFullYear()}-${
      weekNumber < 10 ? "0" : ""
    }${weekNumber}`;
  } else if (bin === MONTHLY) {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month < 10 ? "0" : ""}${month}`;
  } else {
    // QUARTERLY
    const month = Math.floor(date.getMonth() / 3) + 1;

    const year = date.getFullYear();
    return `${year}-${month}`;
  }
}

export default function StackedAreaChartComponent({
  entries,
  selectedHabits,
  selectedFromYear,
  selectedToYear,
  currentBin,
}: StackedAreaChart) {
  const filteredEntries = entries.filter((e) => {
    const useDate = new Date(e.useDate);
    return (
      selectedHabits.includes(e.drugName) &&
      useDate >= new Date(`${selectedFromYear}-01-01`) &&
      useDate <= new Date(`${selectedToYear}-12-31`)
    );
  });
  const colors = getColorArray(selectedHabits.length);
  const bins: Record<string, Record<string, number>> = {};

  const firstDate = Math.min(...filteredEntries.map((e) => e.useDate));
  const lastDate = Math.max(...filteredEntries.map((e) => e.useDate));

  const defaultEntry: Record<string, number> = {};
  selectedHabits.forEach((h) => {
    defaultEntry[h] = 0;
  });

  for (
    let d = new Date(firstDate);
    d <= new Date(lastDate);
    d.setDate(d.getDate() + 1)
  ) {
    const binKey = getBinKey(d, currentBin);
    if (!(binKey in bins)) {
      bins[binKey] = { ...defaultEntry };
    }
  }

  filteredEntries.forEach((e) => {
    const useDate = new Date(e.useDate);
    const binKey = getBinKey(useDate, currentBin);
    console.log(binKey, e.drugName, useDate);
    if (!(binKey in bins)) {
      bins[binKey] = { ...defaultEntry };
    }
    bins[binKey][e.drugName] += 1;
  });

  const data = Object.keys(bins)
    .map((binKey) => {
      return {
        name: binKey,
        ...bins[binKey],
      };
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      return 1;
    });

  return (
    <Grid container spacing={1}>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={data}
          margin={{
            top: 30,
            right: 0,
            left: -30,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {selectedHabits.map((h, i) => {
            return (
              <Area
                type="monotone"
                dataKey={h}
                stackId="1"
                stroke={colors[i]}
                fill={colors[i]}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import SwipeableListItem from "mui-swipeable-list-item";
import { Delete } from "@material-ui/icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, deleteDrug, getAllPrivateDrugs } from "../../logic/Firebase";
import { checkUserAndSession } from "../../logic/effects";
import { Drug } from "../../types";
import Loading from "../Loading";

const background = {
  actionIconLeft: <Delete />,
  actionIconRight: <React.Fragment />,
  backgroundColorLeft: `red`,
  backgroundColorRight: `green`,
};

type Props = {
  setLastUpdated: (x: number) => void,
  lastUpdated: number | undefined
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100%",
  },
  listContainer: {
    marginBottom: 64,
  },
}));

export default function DrugList({ lastUpdated, setLastUpdated }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const user = useAuthState(auth)[0];

  //
  const [entries, setEntries] = useState<Array<Drug>>();
  useEffect(() => {
    async function fetch() {
      const result = await getAllPrivateDrugs();
      setEntries(result);
    }
    if (user) {
      fetch();
    }
  }, [setEntries, user, lastUpdated]);

  useEffect(() => {
    checkUserAndSession(user, history);
  }, [user, history]);

  if (entries === null || entries === undefined) {
    return <Loading />;
  }
  return (
    <>
      <Typography component="h1" variant="h5">
        My Drugs
      </Typography>
      <Grid container spacing={3} className={classes.listContainer}>
        <Grid item xs={12}>
          <List > 
            {entries.map((e) => (
              <SwipeableListItem
                background={background}
                onSwipedLeft={() => {
                  deleteDrug(e);
                  setLastUpdated(Date.now());
                }}
                onSwipedRight={() => alert('no op')}
                primaryText={`${e.name} `}
                secondaryText={`${e.increment} ${e.unit} ${
                  e.healthy ? "(healthy)" : ""
                }`}
                key={e.id}
              ></SwipeableListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { OverviewItem } from "../../types";

type Props = {
  overviewList: Array<OverviewItem>;
};

export default function BarChartComponent({ overviewList }: Props) {
  return (
    <Grid container spacing={3}>
      <ResponsiveContainer height={350} width="95%">
        <BarChart
          data={overviewList}
          layout={"vertical"}
          margin={{
            top: 10,
            right: 5,
            left: 30,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            label={{ value: "Days", position: "insideBottomRight", dy: 10 }}
          />
          <YAxis type="category" dataKey="secondary" interval={0} />
          <Bar dataKey="primary" fill="#3f51b5" />
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
}

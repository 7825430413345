import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";

export const WEEKLY = "Weekly";
export const MONTHLY = "Monthly";
export const QUARTERLY = "Quarterly";

type BinningPickerProps = {
  setCurrentBin: (bin: string) => void;
  currentBin: string;
};

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
}));

export default function BinningPicker({
  currentBin,
  setCurrentBin,
}: BinningPickerProps) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.buttonGroup}>
        <ButtonGroup color="primary">
          <Button
            color={currentBin === WEEKLY ? "primary" : "secondary"}
            onClick={() => setCurrentBin(WEEKLY)}
          >
            Weekly
          </Button>
          <Button
            color={currentBin === MONTHLY ? "primary" : "secondary"}
            onClick={() => setCurrentBin(MONTHLY)}
          >
            Monthly
          </Button>
          <Button
            color={currentBin === QUARTERLY ? "primary" : "secondary"}
            onClick={() => setCurrentBin(QUARTERLY)}
          >
            Quarterly
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

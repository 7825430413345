import React, { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";

import { ResponsiveCalendar, CalendarTooltipProps } from "../calendar";
import { EnhancedRecord } from "../../types";
import YearPicker from "../Pickers/YearPicker";

type CalendarTooltipPropsNew = CalendarTooltipProps & { date?: Date };
const ALL = "ALL";
const HEALTHY = 0;
const UNHEALTHY = 1;
const COLORS_HEALTY = ["#006600", "#00CC00", "#33FF33"];
const COLORS_MIXED = ["#CCFF99", "#FFFF99", "#FFCC99"];
const COLORS_UNHEALTY = ["#FF3333", "#E60000", "#990000"];

type Props = {
  entries: Array<EnhancedRecord>;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  calanderHeatmap: {
    minHeight: "500px",
    maxHeight: "1750px",
  },
}));

const formatDate = (date: number | Date): string => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export default function Calendar({ entries }: Props) {
  const now = new Date();
  const [year, setYear] = useState(`${now.getFullYear()}`);
  const [drugFilter, setDrugFilter] = useState(ALL);
  const classes = useStyles();

  const years = new Set<string>();
  const drugs = new Set<string>();

  drugs.add(ALL);
  years.add(year);
  const yearData: {
    [key: string]: {
      [dateKey: string]: Array<number>;
    };
  } = {};
  yearData[year] = {};

  const yearDosis: {
    [key: string]: {
      [dateKey: string]: Array<string>;
    };
  } = {};
  yearDosis[year] = {};

  entries.forEach((e) => {
    const useDate = new Date(e.useDate);
    const curYear = `${useDate.getFullYear()}`;
    years.add(curYear);
    drugs.add(e.drugName);
    if (!(curYear in yearData)) {
      yearData[curYear] = {};
      yearDosis[curYear] = {};
    }
    if (drugFilter === ALL || e.drugName === drugFilter) {
      const dateKey = formatDate(useDate);
      if (!(dateKey in yearData[curYear])) {
        yearData[curYear][dateKey] = [0, 0];
        yearDosis[curYear][dateKey] = [];
      }
      if (e.healthy) {
        yearData[curYear][dateKey][HEALTHY] += 1;
      } else {
        yearData[curYear][dateKey][UNHEALTHY] += 1;
      }
      yearDosis[curYear][dateKey].push(`${e.drugName} ${e.amount}  ${e.unit}`);
    }
  });
  //We calculate the max using times per year for both healthy and unhealty habitz
  const maxValues = Object.entries(yearData[year]).reduce(
    (previousValue: Array<number>, [key, currentValue]) => {
      return [
        Math.max(previousValue[HEALTHY], currentValue[HEALTHY]),
        Math.max(previousValue[UNHEALTHY], currentValue[UNHEALTHY]),
      ];
    },
    [1, 1]
  );

  const data = [];
  for (const [day, values] of Object.entries(yearData[year])) {
    let value = 0;
    //We consider three intervals for the Calender Heatmap:
    // 0-1/3 for only Healthy with decreasing intensity
    // 1/3-2/3 for both Healthy and Unhealthy
    // 2/3-1 for only Unhealthy
    if (values[HEALTHY] === 0 && values[UNHEALTHY] > 0) {
      value = values[UNHEALTHY] / maxValues[UNHEALTHY] / 3 + 2 / 3;
    } else if (values[HEALTHY] > 0 && values[UNHEALTHY] === 0) {
      value = (1 - values[HEALTHY] / maxValues[HEALTHY]) / 3;
    } else {
      value = values[UNHEALTHY] - values[HEALTHY];
      value =
        value > 0 ? value / maxValues[UNHEALTHY] : value / maxValues[HEALTHY];
      value = (value + 1) / 2 / 3 + 1 / 3;
    }

    data.push({
      day,
      value,
    });
  }
  const fromDate = useMemo(
    () => new Date(parseInt(year), 0, 1, 0, 0, 0, 0),
    [year]
  );
  const toDate = useMemo(
    () => new Date(parseInt(year), 11, 31, 23, 59, 59, 999),
    [year]
  );

  const tooltipfunction = (input: CalendarTooltipPropsNew) => {
    if (input.date === undefined) {
      return null;
    }
    const dateKey = formatDate(input.date);
    return (
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          padding: 4,
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 32,
            height: 32,
            backgroundColor: input.color,
            marginRight: 4,
          }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div> {input.day}</div>
          <div>
            {" "}
            {yearDosis[year][dateKey].map((item: string, index: number) => (
              <div key={`tooltipkey${index}`}>{item}</div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h5">
        Calendar heatmap
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <YearPicker years={[...years]} value={year} onChange={setYear} />
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="drug-select-label">Drug</InputLabel>
            <Select
              labelId="drug-select-label"
              id="drug-select-label"
              value={drugFilter}
              onChange={(e) => setDrugFilter(e.target.value as string)}
            >
              {[...drugs].map((y) => (
                <MenuItem value={y} key={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.calanderHeatmap}>
          <ResponsiveCalendar
            data={data}
            from={fromDate}
            to={toDate}
            tooltip={(props) => tooltipfunction(props)}
            emptyColor="#eeeeee"
            colors={[
              ...(maxValues[HEALTHY] > 0 ? COLORS_HEALTY : []),
              ...(maxValues[HEALTHY] > 0 && maxValues[UNHEALTHY] > 0
                ? COLORS_MIXED
                : []),
              ...(maxValues[UNHEALTHY] > 0 ? COLORS_UNHEALTY : []),
            ]}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            direction="vertical"
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import SwipeableListItem from "mui-swipeable-list-item";
import { Delete } from "@material-ui/icons";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth, deleteRecord } from "../../logic/Firebase";
import {
  checkUserAndSession,
  loadAllRecordsWithDrugNames,
} from "../../logic/effects";
import BottomNavigation, { LIST_VIEW_INDEX } from "../BottomNavigation";
import Loading from "../Loading";
import { EnhancedRecord } from "../../types";

const background = {
  actionIconLeft: <Delete />,
  backgroundColorLeft: `red`,
  actionIconRight: <React.Fragment />,
  backgroundColorRight: `green`,
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100%",
  },
  listContainer: {
    marginBottom: 64,
  },
}));

export default function MainView() {
  const classes = useStyles();
  const history = useHistory();
  const user = useAuthState(auth)[0];

  //
  const [entries, setEntries] = useState<Array<EnhancedRecord>>();
  useEffect(() => {
    async function fetch() {
      const result = await loadAllRecordsWithDrugNames();
      setEntries(result);
    }
    if (user) {
      fetch();
    }
  }, [setEntries, user]);

  useEffect(() => {
    checkUserAndSession(user, history);
  }, [user, history]);

  if (entries === null || entries === undefined) {
    return <Loading />;
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Typography component="h1" variant="h5">
        My entries
      </Typography>
      <Grid container spacing={3} className={classes.listContainer}>
        <Grid item xs={12}>
          <List>
            {entries.map((e) => (
              <SwipeableListItem
                background={background}
                onSwipedLeft={() => deleteRecord(e)}
                onSwipedRight={() => alert("No op")}
                primaryText={`${e.drugName} (${new Date(
                  e.useDate
                ).toDateString()})`}
                secondaryText={`${e.amount} ${e.unit}`}
                key={e.id}
              ></SwipeableListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <BottomNavigation currentView={LIST_VIEW_INDEX} />
    </Container>
  );
}

export default function getColorArray(n: number) {
  const colors = [];
  const goldenRatioConjugate = 0.618033988749895;
  let hue = 0; // Start at a random point in the color wheel

  for (let i = 0; i < n; i++) {
    hue += goldenRatioConjugate; // Use the golden ratio to ensure even distribution of colors
    hue %= 1; // Keep hue value within the range [0, 1]
    const color = hslToRgb(hue, 0.7, 0.6); // You can adjust saturation and lightness values based on your preference
    const hex = `#${color[0].toString(16).padStart(2, "0")}${color[1]
      .toString(16)
      .padStart(2, "0")}${color[2].toString(16).padStart(2, "0")}`;
    colors.push(hex);
  }

  return colors;
}

function hslToRgb(h: number, s: number, l: number) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function hueToRgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

import { EnhancedRecord } from "../../types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

type HabitzPickerProps = {
  entries: Array<EnhancedRecord>;
  value: string[];
  onChange: (value: string[]) => void;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    maxWidth: "200px",
  },
}));

export default function HabitzMultiPicker({
  entries,
  onChange,
  value = [],
}: HabitzPickerProps) {
  const classes = useStyles();
  const habits = new Set<string>();
  entries.forEach((e) => habits.add(e.drugName));

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="habitz-select-label">Habit</InputLabel>
      <Select
        multiple
        labelId="habitz-select-label"
        id="habitz-select-label"
        value={value}
        renderValue={(selected) => (selected as string[]).join(", ")}
        onChange={(e) => {
          const habits = e.target.value as string[];
          onChange(habits);
        }}
      >
        {[...habits].map((y) => (
          <MenuItem value={y} key={y}>
            <Checkbox checked={value.includes(y)} />
            <ListItemText primary={y} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextBoxes from "./textBoxes";
import { getColor } from "../../logic/utils";
import { EnhancedRecord } from "../../types";

type Props = {
  entries: Array<EnhancedRecord>;
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  descContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

function pairwise(arr: Array<any>, func: (x: any, y: any) => void) {
  for (var i = 0; i < arr.length - 1; i++) {
    func(arr[i], arr[i + 1]);
  }
}

export default function Overview({ entries }: Props) {
  const classes = useStyles();

  const drugId2Name: {
    [key: string]: string;
  } = {};
  const drugId2Healthy: {
    [key: string]: boolean;
  } = {};
  const drugId2LastTime: {
    [key: string]: number;
  } = {};
  const drugIdUseDates: {
    [key: string]: Array<number>;
  } = {};

  const [useLastTime, setUseLastTime] = useState(true);

  entries.forEach((e) => {
    drugId2Name[e.drugId] = e.drugName;
    drugId2Healthy[e.drugId] = e.healthy;
    if (!(e.drugId in drugId2LastTime)) {
      drugId2LastTime[e.drugId] = e.useDate;
    } else {
      drugId2LastTime[e.drugId] = Math.max(
        e.useDate,
        drugId2LastTime[e.drugId]
      );
    }

    if (!(e.drugId in drugIdUseDates)) {
      drugIdUseDates[e.drugId] = [e.useDate];
    } else {
      drugIdUseDates[e.drugId].push(e.useDate);
    }
  });

  const overviewListAvg = [];
  // remove single list and sort
  for (const [drugId, useDates] of Object.entries(drugIdUseDates)) {
    // sort the time stamps
    // add the date from today
    useDates.push(Date.now());
    useDates.sort((a, b) => b - a);

    const diffs: Array<number> = [];
    // compute the diff
    pairwise(useDates, (current: number, next: number) => {
      const diff = Math.floor(
        (+new Date(current) - +new Date(next)) / (1000 * 60 * 60 * 24)
      );
      diffs.push(diff + 1);
    });
    // compute the average
    const avgDiff = Math.round(
      diffs.reduce((a, v, i) => (a * i + v) / (i + 1))
    );

    overviewListAvg.push({
      secondary: drugId2Name[drugId],
      primary: avgDiff,
      color: getColor(avgDiff, drugId2Healthy[drugId]),
    });
  }

  const overviewListLast = [];
  // compute the totals per day
  const now = new Date().toISOString().slice(0, 10);

  for (const [drugId, maxLastUseTs] of Object.entries(drugId2LastTime)) {
    const useDate = new Date(maxLastUseTs);
    const diff =
      Math.floor((+new Date(now) - +useDate) / (1000 * 60 * 60 * 24)) + 1;
    overviewListLast.push({
      secondary: drugId2Name[drugId],
      primary: diff,
      color: getColor(diff, drugId2Healthy[drugId]),
    });
  }

  const description = useLastTime
    ? "How many days ago each drug has been used."
    : "How many days are on average between usages.";
  const title = useLastTime
    ? "Last time used in days"
    : "Avg time between usage in days";
  const overviewList = useLastTime ? overviewListLast : overviewListAvg;
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h5">
        {title}
      </Typography>
      <Grid container className={classes.buttonContainer}>
        <Grid item xs={12}>
          <ButtonGroup color="primary">
            <Button
              color={!useLastTime ? "primary" : "secondary"}
              onClick={() => setUseLastTime(true)}
            >
              Last Time
            </Button>
            <Button
              color={useLastTime ? "primary" : "secondary"}
              onClick={() => setUseLastTime(false)}
            >
              Average
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <div className={classes.descContainer}>
        <Typography component="span" variant="subtitle1">
          {description}
        </Typography>
      </div>
      {overviewList.length === 0 ? (
        <div className={classes.descContainer}>
          <Typography component="span" variant="subtitle1">
            Computing the average needs at least two entries of the same type
          </Typography>
        </div>
      ) : (
        <TextBoxes overviewList={overviewList} />
      )}
    </Grid>
  );
}

import { User as FirebaseUser } from "firebase/auth";
import * as ROUTES from "../../constants/routes";
import { getAllEntries, getAllDrugs } from "../Firebase";
import { History } from "history";
import { Drug, EnhancedRecord } from "../../types";

export const checkUserAndSession = (
  user: FirebaseUser | undefined,
  history: History
) => {
  if (!user) history.replace(ROUTES.SIGN_IN);
};

export const loadAllRecordsWithDrugNames = async (): Promise<
  Array<EnhancedRecord>
> => {
  const entries = await getAllEntries();
  const drugs = await getAllDrugs();
  // create simple lut for drugs by id
  const drugDict: {
    [key: string]: Drug;
  } = {};

  drugs.forEach((d) => (drugDict[d.id] = d));

  const result = entries
    .filter((e) => e.drugId in drugDict)
    .map(
      (e) =>
        ({
          ...e,
          drugName: drugDict[e.drugId].name,
          healthy: drugDict[e.drugId].healthy,
          unit: drugDict[e.drugId].unit,
        } as EnhancedRecord)
    )
    .sort((a, b) => (a.useDate < b.useDate ? 1 : -1));

  return result;
};

import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { auth } from "../../logic/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { checkUserAndSession } from "../../logic/effects";
import BottomNavigation, { ADD_VIEW_INDEX } from "../BottomNavigation";

import AddEntry from "./addEntry";
import AddDrug from "./addDrug";
import DrugList from "./drugList";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100%",
  },
  formControl: {
    minWidth: "100%",
  },
  saveButton: {
    float: "right",
  },
}));

export default function AddView() {
  const classes = useStyles();
  const history = useHistory();
  const user = useAuthState(auth)[0];
  const [lastUpdated, setLastUpdated] = useState<number>();
  // check if logged in or session expired
  useEffect(() => {
    checkUserAndSession(user, history);
  }, [user, history]);

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <AddEntry lastUpdated={lastUpdated} />
      <AddDrug setLastUpdated={setLastUpdated} />
      <DrugList lastUpdated={lastUpdated} setLastUpdated={setLastUpdated} />
      <BottomNavigation currentView={ADD_VIEW_INDEX} />
    </Container>
  );
}

import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";

type Props = {
  secondary: number | string;
  primary: number | string;
  color?: string;
};
export default function TypographyExample({
  primary,
  secondary,
  color = "black",
}: Props) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" align="center" style={{ color }}>
          {primary}
        </Typography>
        <Typography variant="h5" color="primary" align="center">
          {secondary}
        </Typography>
      </CardContent>
    </Card>
  );
}

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
}));

type YearPickerProps = {
  years: string[];
  value: string;
  onChange: (year: string) => void;
  label?: string;
};

export default function YearPicker({
  years,
  value,
  onChange,
  label = "Year",
}: YearPickerProps) {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="year-select-label">{label}</InputLabel>
      <Select
        labelId="year-select-label"
        id="year-select-label"
        value={value}
        onChange={(e) => onChange(e.target.value as string)}
      >
        {years.map((y) => (
          <MenuItem value={y} key={y}>
            {y}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import BarChart from "./barChart";
import TextBoxes from "./textBoxes";
import { EnhancedRecord } from "../../types";

const ALL = "ALL";

type Props = {
  entries: Array<EnhancedRecord>;
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  formControl: {
    minWidth: "100%",
  },
}));

export default function Overview({ entries }: Props) {
  const classes = useStyles();
  const [totalView, setTotalView] = useState(true);
  const [barChartView, setBarChartView] = useState(false);
  const [year, setYear] = useState(ALL);

  const years = new Set<string>();
  years.add(ALL);

  const drugId2Name: {
    [key: string]: string;
  } = {};
  const drugId2Unit: {
    [key: string]: string;
  } = {};
  const drugId2Count: {
    [key: string]: number;
  } = {};
  const drugId2Amount: {
    [key: string]: number;
  } = {};

  entries.forEach((e) => {
    const useDate = new Date(e.useDate);
    const curYear = `${useDate.getFullYear()}`;
    years.add(curYear);

    if (year === ALL || curYear === year) {
      drugId2Name[e.drugId] = e.drugName;
      drugId2Unit[e.drugId] = e.unit;
      if (e.drugId in drugId2Count) {
        drugId2Count[e.drugId] += 1;
        drugId2Amount[e.drugId] += e.amount;
      } else {
        drugId2Count[e.drugId] = 1;
        drugId2Amount[e.drugId] = e.amount;
      }
    }
  });

  const overviewListTotal = [];
  const overviewListAmount = [];
  // compute the totals per day
  for (const [drugId, total] of Object.entries(drugId2Count)) {
    overviewListTotal.push({
      secondary: drugId2Name[drugId],
      primary: total,
    });
  }

  // compute the totals per amount
  for (const [drugId, amount] of Object.entries(drugId2Amount)) {
    overviewListAmount.push({
      secondary: `${drugId2Unit[drugId]} of ${drugId2Name[drugId]}`,
      primary: amount,
    });
  }

  const overviewList = totalView ? overviewListTotal : overviewListAmount;
  overviewList.sort((a: any, b: any) => (a.primary > b.primary ? -1 : 1));
  const description = totalView
    ? "How many times (days) each drug has been used."
    : "How much of each drug has been used.";
  const handleTotalViewChange = (value: boolean) => {
    setTotalView(value);
    if (!value) {
      setBarChartView(false);
    }
  };
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h5">
        Overview
      </Typography>
      <Grid container className={classes.buttonContainer}>
        <Grid item xs={6}>
          <ButtonGroup color="primary">
            <Button
              color={!totalView ? "primary" : "secondary"}
              onClick={() => handleTotalViewChange(true)}
            >
              Days
            </Button>
            <Button
              color={totalView ? "primary" : "secondary"}
              onClick={() => handleTotalViewChange(false)}
            >
              Amount
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={6}>
          {totalView && (
            <ButtonGroup color="primary">
              <Button
                color={barChartView ? "primary" : "secondary"}
                onClick={() => setBarChartView(false)}
              >
                Text
              </Button>
              <Button
                color={!barChartView ? "primary" : "secondary"}
                onClick={() => setBarChartView(true)}
              >
                Chart
              </Button>
            </ButtonGroup>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="year-select-label">Year</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select-label"
            value={year}
            onChange={(e) => setYear(e.target.value as string)}
          >
            {[...years].map((y) => (
              <MenuItem value={y} key={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Typography
        component="span"
        variant="subtitle1"
        style={{ marginTop: 20 }}
      >
        {description}
      </Typography>
      {barChartView ? (
        <BarChart overviewList={overviewList} />
      ) : (
        <TextBoxes overviewList={overviewList} />
      )}
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Drug } from "../../types";
import { auth, getAllDrugsSortedByUse, addRecord } from "../../logic/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import Loading from "../Loading";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100%",
  },
  formControl: {
    minWidth: "100%",
  },
  saveButton: {
    float: "right",
  },
}));

type Props = {
  lastUpdated: number | undefined;
};

export default function AddEntry({ lastUpdated }: Props) {
  const classes = useStyles();
  const [drugs, setDrugs] = useState<Array<Drug>>([]);
  const user = useAuthState(auth)[0];

  // data
  const [selectedDate, handleDateChange] =
    useState<MaterialUiPickersDate | null>(new Date());
  const [drugId, setDrugId] = useState<string>("");
  const [amount, setAmount] = useState<number>(-1);
  const [savingData, setSavingData] = useState<boolean>(false);

  // load all the drug data
  useEffect(() => {
    async function fetchData() {
      const result = await getAllDrugsSortedByUse();
      setDrugs(result);
      if (result.length > 0) {
        setDrugId(result[0].id);
        setAmount(result[0].increment);
      }
    }
    fetchData();
  }, [setDrugs, setDrugId, setAmount, lastUpdated]);

  const handleDrugChange = (id: string) => {
    const drug = drugs.find((d) => d.id === id);
    if (drug) {
      setDrugId(id);
      setAmount(drug.increment);
    }
  };

  const saveEntry = async () => {
    setSavingData(true);
    await addRecord(
      user.uid,
      drugId,
      amount,
      selectedDate ? selectedDate.getTime() : Date.now(),
      Date.now()
    );
    setSavingData(false);
    alert("Entry Saved");
  };

  if (drugs.length === 0 || drugId === "") {
    return <Loading />;
  }
  let increment = 0;
  let unit = "";
  const curDrug = drugs.find((d) => d.id === drugId);
  if (curDrug) {
    increment = curDrug.increment;
    unit = curDrug.unit;
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Add Entry
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Entry date"
              value={selectedDate}
              onChange={handleDateChange}
              className={classes.formControl}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id="drug-select-label">Drug</InputLabel>
            <Select
              labelId="drug-select-label"
              id="drug-select"
              value={drugId}
              onChange={(e) => handleDrugChange(e.target.value as string)}
            >
              {drugs.map((d) => (
                <MenuItem value={d.id} key={d.id}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id="drug-select-label">Amount in {unit}</InputLabel>
            <Select
              labelId="drug-select-label"
              id="drug-select"
              value={amount}
              onChange={(e) => setAmount(parseInt(e.target.value as string))}
            >
              {[...Array(10).keys()].map((n, i) => (
                <MenuItem value={(n + 1) * increment} key={i}>
                  {(n + 1) * increment}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={drugId === "" || amount === -1 || savingData}
            className={classes.saveButton}
            onClick={() => saveEntry()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

import { EnhancedRecord } from "../../types";
import Grid from "@material-ui/core/Grid";

import YearPicker from "../Pickers/YearPicker";

type FromToYearPickerProps = {
  entries: EnhancedRecord[];
  selectedFromYear: string;
  selectedToYear: string;
  setSelectedFromYear: (year: string) => void;
  setSelectedToYear: (year: string) => void;
};

export default function FromToYearPicker({
  entries,
  selectedFromYear,
  selectedToYear,
  setSelectedFromYear,
  setSelectedToYear,
}: FromToYearPickerProps) {
  const minYear = new Date(
    Math.min(...entries.map((e) => e.useDate))
  ).getFullYear();

  const maxYear = new Date(
    Math.max(...entries.map((e) => e.useDate))
  ).getFullYear();

  const years = [];
  for (let i = minYear; i <= maxYear; i++) {
    years.push(`${i}`);
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <YearPicker
          years={years}
          value={selectedFromYear}
          onChange={setSelectedFromYear}
          label="Start year"
        />
      </Grid>
      <Grid item xs={6}>
        <YearPicker
          label="End year"
          years={years.filter((y) => y >= selectedFromYear)}
          value={selectedToYear}
          onChange={setSelectedToYear}
        />
      </Grid>
    </Grid>
  );
}

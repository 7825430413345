export const firebaseConfigProd = {
  apiKey: "AIzaSyBKZTo7yoLuPd3sYqJfL8sKCd3VuN6883M",
  authDomain: "drugdb-da621.firebaseapp.com",
  projectId: "drugdb-da621",
  storageBucket: "drugdb-da621.appspot.com",
  messagingSenderId: "442316140273",
  appId: "1:442316140273:web:35c4215f01d3c894f90350",
};

export const firebaseConfigDev = {
  apiKey: "AIzaSyCA2kGgKaCyhiUblBQZvnnaXiwee80yKes",
  authDomain: "drugdbdev.firebaseapp.com",
  projectId: "drugdbdev",
  storageBucket: "drugdbdev.appspot.com",
  messagingSenderId: "995626377650",
  appId: "1:995626377650:web:bd4b7b117a94162a2a44ab",
};

export const COLLECTIONS = {
  DRUGS: "drugs",
  RECORDS: "records",
};

export const getFirebaseConfig = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? firebaseConfigDev
    : firebaseConfigProd;

import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { useAuthState } from "react-firebase-hooks/auth";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import BottomNavigation, { STATS_VIEW_INDEX } from "../BottomNavigation";
import { auth } from "../../logic/Firebase";
import {
  checkUserAndSession,
  loadAllRecordsWithDrugNames,
} from "../../logic/effects";
import Loading from "../Loading";
import Overview from "./overview";
import LastTimeUsed from "./lastTime";
import Calendar from "./calendarHeatmap";
import Balance from "./balance";
import { EnhancedRecord } from "../../types";

const useStyles = makeStyles((theme) => ({
  statsContainer: {
    marginBottom: 64,
  },
  descContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default function StatsView() {
  const [entries, setEntries] = useState<Array<EnhancedRecord>>();
  const classes = useStyles();
  const history = useHistory();
  const user = useAuthState(auth)[0];

  useEffect(() => {
    async function fetch() {
      const result = await loadAllRecordsWithDrugNames();
      setEntries(result);
    }

    if (user) {
      fetch();
    }
  }, [setEntries, user]);

  useEffect(() => {
    checkUserAndSession(user, history);
  }, [user, history]);

  if (entries === null || entries === undefined) {
    return <Loading />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Stats
      </Typography>
      <div className={classes.descContainer}>
        <Typography component="span" variant="subtitle1">
          This page contains some statistics about your records
        </Typography>
      </div>
      {entries.length === 0 ? (
        <div className={classes.descContainer}>
          <Typography component="span" variant="subtitle1">
            No entries found! Add some data.
          </Typography>
        </div>
      ) : (
        <Grid container spacing={3} className={classes.statsContainer}>
          <hr />
          <Balance entries={entries} />
          <Overview entries={entries} />
          <LastTimeUsed entries={entries} />
          <Calendar entries={entries} />
        </Grid>
      )}

      <BottomNavigation currentView={STATS_VIEW_INDEX} />
    </Container>
  );
}

import { COLORS } from "../../constants/colors";
import { WEEK, MONTH } from "../../constants/bounds";

const getColor = (value, fliped = false) => {
  let index;
  if (value < WEEK) {
    index = 0;
  } else if (value < MONTH) {
    index = 1;
  } else {
    index = 2;
  }
  if (fliped) {
    index = 2 - index;
  }
  return COLORS[index];
};

export { getColor };

import React from "react";
import Grid from "@material-ui/core/Grid";
import NumberWithDescribtion from "../text/numberWithDescribtion";
import { OverviewItem } from "../../types";

type Props = {
  overviewList: Array<OverviewItem>;
};

export default function TextBoxes({ overviewList }: Props) {
  return (
    <Grid container spacing={3}>
      {overviewList.map((o: OverviewItem, i: number) => (
        <Grid item xs key={i}>
          <NumberWithDescribtion
            primary={o.primary}
            secondary={o.secondary}
            color={o.color}
          />
        </Grid>
      ))}
    </Grid>
  );
}

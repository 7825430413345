import React, { useEffect } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { makeStyles } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TimelineIcon from "@material-ui/icons/Timeline";
import { useHistory } from "react-router-dom";
import { History } from "history";
import { auth, logout } from "../../logic/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import * as ROUTES from "../../constants/routes";
import { checkUserAndSession } from "../../logic/effects";

const useStyles = makeStyles((theme) => ({
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    transform: "translate(calc(50vw - 50%))",
  },
}));

type Props = {
  currentView: number;
};

const STATS_VIEW_INDEX = 0;
const CHART_VIEW_INDEX = 1;
const ADD_VIEW_INDEX = 2;
const LIST_VIEW_INDEX = 3;
const LOGOUT_INDEX = 4;

export default function BottomView(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const user = useAuthState(auth)[0];

  useEffect(() => {
    checkUserAndSession(user, history);
  }, [user, history]);

  const handleNavigation = (buttonIndex: number, history: History) => {
    // log out index
    if (buttonIndex === LOGOUT_INDEX) {
      logout();
    } else if (buttonIndex === LIST_VIEW_INDEX) {
      history.replace(ROUTES.LIST_VIEW);
    } else if (buttonIndex === STATS_VIEW_INDEX) {
      history.replace(ROUTES.STATS_VIEW);
    } else if (buttonIndex === ADD_VIEW_INDEX) {
      history.replace(ROUTES.ADD_VIEW);
    } else if (buttonIndex === CHART_VIEW_INDEX) {
      history.replace(ROUTES.CHART_VIEW);
    }
  };

  return (
    <BottomNavigation
      value={props.currentView}
      onChange={(_, newValue) => {
        handleNavigation(newValue, history);
      }}
      showLabels
      className={classes.stickToBottom}
    >
      <BottomNavigationAction label="Stats" icon={<EqualizerIcon />} />
      <BottomNavigationAction label="Charts" icon={<TimelineIcon />} />
      <BottomNavigationAction label="Add" icon={<AddCircleOutlineIcon />} />
      <BottomNavigationAction label="Data" icon={<ListIcon />} />
      <BottomNavigationAction label="Sign out" icon={<ExitToAppIcon />} />
    </BottomNavigation>
  );
}

export {
  LIST_VIEW_INDEX,
  ADD_VIEW_INDEX,
  STATS_VIEW_INDEX,
  LOGOUT_INDEX,
  CHART_VIEW_INDEX,
};

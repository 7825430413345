import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import SignInPage from "../SignIn";
import SignUpPage from "../SignUp";
import ListView from "../ListView";
import StatsView from "../StatsView";
import AddView from "../AddView";
import ChartView from "../ChartView";

import ResetPassword from "../ForgotPassword";
import * as ROUTES from "../../constants/routes";

const App = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <div style={{ marginTop: 15 }}>
      <Switch>
        <Route exact path={"/"} component={SignInPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.PASSWORD_RESET} component={ResetPassword} />
        <Route path={ROUTES.LIST_VIEW} component={ListView} />
        <Route path={ROUTES.STATS_VIEW} component={StatsView} />
        <Route path={ROUTES.ADD_VIEW} component={AddView} />
        <Route path={ROUTES.CHART_VIEW} component={ChartView} />
        <Route component={SignInPage} />
      </Switch>
    </div>
  </Router>
);

export default App;

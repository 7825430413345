import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  addDoc,
  getDocs,
  getDocsFromCache,
  getDocsFromServer,
  doc,
  deleteDoc,
  writeBatch,
  enableIndexedDbPersistence,
} from "firebase/firestore";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import { getFirebaseConfig, COLLECTIONS } from "../../constants/fireConfig";
import { Record, Drug, DrugWithCount } from "../../types";

initializeApp(getFirebaseConfig());
const auth = getAuth();
const db = getFirestore();
let DRUGS_CACHED = false;
let ENTRIES_CACHED = false;

const handelError = (err: any) => {
  console.error(err);
  if (err.message) {
    alert(err.message);
  }
};

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    console.error(err);
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
    console.error(err);
  }
});
// Subsequent queries will use persistence, if it was enabled successfully

const signIn = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email.trim(), password);
  } catch (err) {
    handelError(err);
  }
};

const registerWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  try {
    await createUserWithEmailAndPassword(auth, email.trim(), password);
  } catch (err) {
    handelError(err);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email.trim());
    alert("Password reset link sent!");
  } catch (err) {
    handelError(err);
  }
};
const logout = async () => {
  try {
    await signOut(auth);
    console.log("Sign out successfull");
  } catch (err) {
    handelError(err);
  }
};

const addRecord = async (
  ownerId: string,
  drugId: string,
  amount: number,
  useDate: number,
  recordTimestamp: number
) => {
  try {
    const record = {
      ownerId,
      drugId,
      amount,
      useDate,
      recordTimestamp,
    };
    await addDoc(collection(db, COLLECTIONS.RECORDS), record);
  } catch (err) {
    handelError(err);
  }
};

const addDrug = async (
  ownerId: string,
  name: string,
  unit: string,
  increment: number,
  healthy: boolean
) => {
  try {
    const newDrug = {
      ownerId,
      name,
      unit,
      increment,
      healthy,
    };
    await addDoc(collection(db, COLLECTIONS.DRUGS), newDrug);
  } catch (err) {
    handelError(err);
  }
};

const deleteRecord = async (record: Record) => {
  try {
    await deleteDoc(doc(db, COLLECTIONS.RECORDS, record.id));
  } catch (err) {
    handelError(err);
  }
};

const deleteDrug = async (drug: Drug) => {
  try {
    // first delete all records with the drug id then the category
    const recordsRef = collection(db, COLLECTIONS.DRUGS);
    const q = query(recordsRef, where("drugId", "==", drug.id));
    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);
    querySnapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Commit the batch
    await batch.commit();

    await deleteDoc(doc(db, COLLECTIONS.DRUGS, drug.id));
  } catch (err) {
    handelError(err);
  }
};
const getAllDrugsSortedByUse = async (): Promise<Array<Drug>> => {
  try {
    const user = auth.currentUser;
    if (user === null) {
      return [];
    }
    const [drugs, entries] = await Promise.all([
      getAllDrugs(),
      getAllEntries(),
    ]);
    // count
    const id2Drug: {
      [key: string]: DrugWithCount;
    } = {};

    drugs.forEach((d) => {
      id2Drug[d.id] = { ...d, count: 0 };
    });

    entries.forEach(({ drugId, id }) => {
      try {
        id2Drug[drugId]["count"]++;
      } catch (e) {
        console.error(`record with id ${id} has invalid drugId ${drugId}`);
      }
    });
    const sortedDrugs = Object.values(id2Drug).sort(
      (a: DrugWithCount, b: DrugWithCount) => (a.count > b.count ? -1 : 1)
    );

    return sortedDrugs.map((d: DrugWithCount) => d as Drug);
  } catch (err) {
    handelError(err);
    return [];
  }
};
const getAllDrugs = async (): Promise<Array<Drug>> => {
  try {
    const user = auth.currentUser;
    if (user === null) {
      return [];
    }
    const recordsRef = collection(db, COLLECTIONS.DRUGS);
    // fetch the public records
    const q = query(recordsRef, where("ownerId", "==", ""));
    const querySnapshot = await (DRUGS_CACHED
      ? getDocsFromCache(q)
      : getDocsFromServer(q));
    let result = querySnapshot.docs.map((doc) => {
      const curDrug = {
        id: doc.id,
        ...doc.data(),
      } as Drug;
      return curDrug;
    });

    result = result.concat(await getAllPrivateDrugs(DRUGS_CACHED));
    DRUGS_CACHED = true;
    return result;
  } catch (err) {
    handelError(err);
    return [];
  }
};

const getAllPrivateDrugs = async (cached = true): Promise<Array<Drug>> => {
  try {
    const user = auth.currentUser;
    if (user === null) {
      return [];
    }
    const recordsRef = collection(db, COLLECTIONS.DRUGS);
    const result: Array<Drug> = [];
    const qPrivate = query(recordsRef, where("ownerId", "==", user.uid));
    const querySnapshotPrivate = await (cached
      ? getDocsFromCache(qPrivate)
      : getDocsFromServer(qPrivate));
    querySnapshotPrivate.docs.forEach((doc) => {
      const curDrug = doc.data() as Drug;
      curDrug.id = doc.id;
      result.push(curDrug);
    });
    return result;
  } catch (err) {
    handelError(err);
    return [];
  }
};

const getAllEntries = async (): Promise<Array<Record>> => {
  try {
    const user = auth.currentUser;
    if (user === null) {
      return [];
    }
    const recordsRef = collection(db, COLLECTIONS.RECORDS);
    const q = query(recordsRef, where("ownerId", "==", user.uid));
    const querySnapshot = await (ENTRIES_CACHED
      ? getDocsFromCache(q)
      : getDocsFromServer(q));
    const result = querySnapshot.docs.map((doc) => {
      const curRecord = {
        id: doc.id,
        ...doc.data(),
      };
      return curRecord as Record;
    });
    ENTRIES_CACHED = true;
    return result;
  } catch (err) {
    handelError(err);
    return [];
  }
};

export {
  auth,
  db,
  signIn,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  // db function
  getAllDrugs,
  getAllDrugsSortedByUse,
  getAllPrivateDrugs,
  getAllEntries,
  addRecord,
  addDrug,
  deleteRecord,
  deleteDrug,
};

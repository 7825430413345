import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../logic/Firebase";
import {
  checkUserAndSession,
  loadAllRecordsWithDrugNames,
} from "../../logic/effects";
import BottomNavigation, { CHART_VIEW_INDEX } from "../BottomNavigation";
import Loading from "../Loading";
import { EnhancedRecord } from "../../types";
import FromToYearPicker from "./FromToPicker";
import BinningPicker, { MONTHLY } from "./BinningPicker";
import HabitzMultiPicker from "./HabitzPicker";
import StackedAreaChartComponent from "./StackedAreaChart";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100%",
  },
}));

export default function MainView() {
  const classes = useStyles();
  const history = useHistory();
  const user = useAuthState(auth)[0];
  const [selectedHabits, setSelectedHabits] = useState<string[]>([]);
  const [selectedFromYear, setSelectedFromYear] = useState<string>("2020");
  const [selectedToYear, setSelectedToYear] = useState<string>("2023");
  const [currentBin, setCurrentBin] = useState(MONTHLY);

  const [entries, setEntries] = useState<Array<EnhancedRecord>>();
  useEffect(() => {
    async function fetch() {
      const result = await loadAllRecordsWithDrugNames();
      setEntries(result);
      const minYear = new Date(
        Math.min(...result.map((e) => e.useDate))
      ).getFullYear();

      const maxYear = new Date(
        Math.max(...result.map((e) => e.useDate))
      ).getFullYear();
      setSelectedFromYear(`${minYear}`);
      setSelectedToYear(`${maxYear}`);

      const habits: Record<string, number> = {};
      result.forEach((e) => {
        habits[e.drugName]
          ? (habits[e.drugName] = 0)
          : (habits[e.drugName] += 1);
      });
      const habitNames = Object.keys(habits).sort((a, b) => {
        return habits[b] - habits[a];
      });
      setSelectedHabits(habitNames.slice(0, 3));
    }
    if (user) {
      fetch();
    }
  }, [setEntries, user]);

  useEffect(() => {
    checkUserAndSession(user, history);
  }, [user, history]);

  if (entries === null || entries === undefined) {
    return <Loading />;
  }

  return (
    <Container component="main" className={classes.container}>
      <Typography component="h1" variant="h5">
        My Charts
      </Typography>
      <FromToYearPicker
        entries={entries}
        setSelectedFromYear={setSelectedFromYear}
        setSelectedToYear={setSelectedToYear}
        selectedFromYear={selectedFromYear}
        selectedToYear={selectedToYear}
      />
      <BinningPicker currentBin={currentBin} setCurrentBin={setCurrentBin} />
      <HabitzMultiPicker
        entries={entries}
        onChange={setSelectedHabits}
        value={selectedHabits}
      />
      <StackedAreaChartComponent
        entries={entries}
        selectedHabits={selectedHabits}
        selectedFromYear={selectedFromYear}
        selectedToYear={selectedToYear}
        currentBin={currentBin}
      />
      <BottomNavigation currentView={CHART_VIEW_INDEX} />
    </Container>
  );
}
